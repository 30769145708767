import '../styles/index.scss';
import 'slick-carousel/slick/slick';
import 'jquery.nicescroll/jquery.nicescroll';
import 'bootstrap';
import functions from './functions.js';
import menu from './menu.js';
import modernizrCustom from './modernizr-custom.js';

class Main {
	constructor() {
		this.onDOMLoaded();
		this.onDocumentLoad();
		this.onResize();
		this.onScroll();
		window.jQuery = window.$ = $;
	}

	onDocumentLoad() {
		window.addEventListener("load", (event) => {
			functions.filterTabs();
			functions.verticalFilter();
			functions.accordion();
			functions.searchBar();
			functions.slider();
		});
	}

	onDOMLoaded() {
		document.addEventListener("DOMContentLoaded", (event) => {
			functions.cookies();


		});

	}

	onResize() {
		window.addEventListener('resize', (event) => {

		});
	}
	onScroll() {
		window.addEventListener('scroll', (event) => {

		});
	}

}

new Main();


