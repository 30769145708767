import 'slick-carousel/slick/slick';

function cookies() {
	// $("body").niceScroll();
	var cookieInfo = $('.cookie'),
		cookieClose = $('.cookie').find('.cookie-accept');
	checkCookie();

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + expires + "; path=/";

	};
	function getCookie(cname) {

		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1);
			if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
		}
		return '';
	};

	function checkCookie() {
		var isExist = getCookie('pa-media');
		if (isExist != '') {
			cookieInfo.hide();
		}
		else {
			cookieInfo.show();
			cookieClose.on('click', function (e) {
				e.preventDefault();
				cookieInfo.hide();
				isExist = true;
				if (isExist != "" && isExist != null) {
					setCookie('pa-media', isExist, 365);
				}
			});
		}
	};

};

function filterTabs() {
	const filters = $('[data-filter]');
	const listings = $('[data-listing]');
	if (filters.length > 0) {
		filters.each((i, el) => {
			const filter = $(el);
			const listing = $('[data-listing="' + filter.data('filter') + '"]');

			filter.on('click', () => {
				if (!filter.hasClass('current')) {
					filters.removeClass('current');
					listings.removeClass('active');
					filter.addClass('current');
					listing.addClass('active');
				}
			});
		});
	}
}
function verticalFilter() {
	const filters = $('[data-tab]');
	const listings = $('[data-content]');
	if (filters.length > 0) {
		filters.each((i, el) => {
			const filter = $(el);
			const listing = $('[data-content="' + filter.data('tab') + '"]');
			filter.on('click', () => {
				if (!filter.hasClass('current')) {
					filters.removeClass('current');
					listings.removeClass('active');
					filter.addClass('current');
					listing.addClass('active');
				}
			});
		});
	}
}

function accordion() {
	let accordion = document.querySelectorAll(".accordion__outside");
	if (accordion.length > 0) {
		accordion.forEach(el => {
			el.addEventListener("click", function (e) {
				var clicked = e.currentTarget;
				let arrow = clicked.querySelector('.accordion__arrow');
				var panel = this.nextElementSibling;
				let insideELement = panel.querySelector('.inside__element');
				if (panel.style.height) {
					panel.style.height = null;
					arrow.classList.remove('rotated');
					this.classList.toggle("active");
				} else {
					let everyPanel = document.querySelectorAll('.active');
					everyPanel.forEach((e) => {
						e.classList.remove('active');
					});
					let everyInsideEl = document.querySelectorAll('.accordion__inside');
					everyInsideEl.forEach((e) => {
						e.style.height = null;
					});
					let everyRotaded = document.querySelectorAll('.rotated');
					everyRotaded.forEach((el) => {
						el.classList.remove('rotated');
					});
					this.classList.toggle("active");
					arrow.classList.add('rotated');
					panel.style.height = insideELement.scrollHeight + 50 + "px";
				}
			});
		});
		accordion[0].click();
	}
}
function searchBar() {
	const search = document.querySelector('.icon-desktop');
	const searchBar = document.querySelector('.search-bar-desktop');
	const searchBarMobile = document.querySelector('.search-bar');
	const searchMobile = document.querySelector('.icon-search');
	const mobileClose = document.querySelector('body section');
	if (search) {
		search.addEventListener('click', (e) => {
			let windowWidth = window.innerWidth;
			if (searchBar.style.width) {
				searchBar.style.width = null;
				searchBar.style.paddingLeft = null;
				searchBar.style.border = null;
				if (!search.classList.contains('no-white')) {
					search.classList.add('white');
				}
			} else {
				if (windowWidth > 1200) {
					searchBar.style.width = 351 + 'px';
				} else {
					searchBar.style.width = 200 + 'px';
				}
				searchBar.style.paddingLeft = 15 + 'px';
				searchBar.style.border = "2px solid black";
				search.classList.remove('white');
			}
		});
	}
	if (searchMobile) {
		searchMobile.addEventListener('click', (e) => {
			if (searchBarMobile.style.width) {
				searchBarMobile.style.width = null;
				searchBarMobile.style.paddingLeft = null;
			} else {
				searchBarMobile.style.width = 120 + 'px';
				searchBarMobile.style.paddingLeft = 15 + 'px';
			}
		});
		mobileClose.addEventListener('click', (el) => {
			searchBarMobile.style.width = null;
			searchBarMobile.style.paddingLeft = null;
		});
	}
}

function slider() {
	let homepage = document.querySelector('.homepage');
	if (homepage) {
		$('.slider-home').slick({
			swipe: true,
			touchMove: true,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 2000,
			cssEase: 'ease-in-out',
			touchThreshold: 100
		});
	}
	let prSlider = document.querySelector('.pr-slider');
	console.log(prSlider);
	if (prSlider) {
		$('.pr-slider').slick({
			arrows: true,
			prevArrow: `<div class="prev-arrow">
				<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;" xml:space="preserve">
				<g>
					<g>
						<path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
							c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
							c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
							c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"/>
					</g>
				</g>
				</svg>
			 </div>`,
			nextArrow:
				`<div class="next-arrow">
				<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;" xml:space="preserve">
				<g>
					<g>
						<path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
							c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
							c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
							c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"/>
					</g>
				</g>
				</svg>
			  </div>`,
			adaptiveHeight: true,
		});
	}
}


export default { cookies, filterTabs, verticalFilter, accordion, searchBar, slider };